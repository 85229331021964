import React, { Component } from 'react'

class LinkedIn extends Component {
  render() {
    let { color } = this.props

    return (
      <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-59.000000, -423.000000)" fill={ color || "#FFFFFF" }>
            <path d="M60.8652108,423 C59.8345118,423 59,423.834512 59,424.865211 C59,425.89591 59.8345118,426.730064 60.8652108,426.730064 C61.8959098,426.730064 62.7300635,425.89591 62.7300635,424.865211 C62.7300635,423.834512 61.8959098,423 60.8652108,423 Z M74.9119305,431.60861 C74.6663385,429.586593 73.7441153,428.33035 71.0150362,428.33035 C69.4147498,428.33035 68.335004,428.91927 67.8835586,429.773114 L67.8345118,429.773114 L67.8345118,428.33035 L64.8598407,428.33035 L64.8598407,430.381724 L64.8598407,439 L67.971986,439 L67.971986,433.709747 C67.971986,432.315314 68.2465766,430.971002 69.9553388,430.971002 C71.6723351,430.971002 71.7997852,432.560906 71.7997852,433.807482 L71.7997852,439 L75,439 L75,433.140159 C75,432.590262 74.9702855,432.080104 74.9119305,431.60861 Z M59,431.166831 L59,439 L62.7300635,439 L62.7300635,430.528864 L62.7300635,428.33035 L59,428.33035 L59,431.166831 Z" id="Fill-1"></path>
          </g>
        </g>
      </svg>
    )
  }
}

class Twitter extends Component {
  render() {
    let { color } = this.props

    return (
      <svg width="18px" height="16px" viewBox="0 0 18 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1152.000000, -128.000000)" fill={ color || "#FFFFFF" }>
            <g transform="translate(1152.000000, 127.000000)">
              <g>
                <path d="M17.3999077,3.24490353 C16.756565,3.56302118 16.072565,3.76857412 15.3455161,3.86400941 C16.0845231,3.37704471 16.6537259,2.61111529 16.921586,1.69102118 C16.2304112,2.14128 15.4650965,2.46184471 14.649558,2.64782118 C14.0014322,1.87944471 13.0687049,1.40226824 12.0450965,1.40226824 C10.0744112,1.40226824 8.47681678,3.16170353 8.47681678,5.33469176 C8.47681678,5.64057412 8.50790769,5.94156235 8.56530629,6.22542118 C5.6044951,6.07125647 2.97133427,4.49779765 1.20871888,2.12415059 C0.902593007,2.70410353 0.728005594,3.37704471 0.728005594,4.10137412 C0.728005594,5.46193882 1.3569986,6.67323294 2.31603357,7.36819765 C1.73008951,7.34372706 1.18001958,7.17488 0.699306294,6.87878588 L0.699306294,6.93262118 C0.699306294,8.83398588 1.92859301,10.4221271 3.56206154,10.7891859 C3.26311049,10.87728 2.94741818,10.91888 2.61976783,10.91888 C2.39017343,10.91888 2.17253706,10.9017506 1.95250909,10.8479153 C2.40452308,12.41648 3.72469091,13.5543624 5.28641119,13.5788329 C4.0642993,14.6384094 2.5241035,15.2599624 0.852369231,15.2599624 C0.562984615,15.2599624 0.278383217,15.2428329 0.000956643356,15.2061271 C1.57941818,16.32688 3.45204755,16.9802447 5.47295664,16.9802447 C12.0379217,16.9802447 15.6301175,10.9849506 15.6301175,5.78984471 C15.6301175,5.62344471 15.6253343,5.45459765 15.6181594,5.28819765 C16.3141175,4.72537412 16.921586,4.03040941 17.3999077,3.24490353"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class Facebook extends Component {
  render() {
    let { color } = this.props

    return (
      <svg width="9px" height="17px" viewBox="0 0 9 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1234.000000, -128.000000)" fill={ color || "#FFFFFF"}>
            <g transform="translate(1152.000000, 125.000000)">
              <g>
                <path d="M90.1683203,8.34875294 L87.6738727,8.34875294 L87.6738727,6.67496471 C87.6738727,6.04607059 88.0804462,5.89924706 88.3674392,5.89924706 L90.1276629,5.89924706 L90.1276629,3.13407059 L87.702572,3.12428235 C85.0120126,3.12428235 84.3997608,5.18715294 84.3997608,6.50611765 L84.3997608,8.34875294 L82.8428238,8.34875294 L82.8428238,11.1971294 L84.3997608,11.1971294 L84.3997608,19.2552941 L87.6738727,19.2552941 L87.6738727,11.1971294 L89.8813273,11.1971294 L90.1683203,8.34875294 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class Smile extends Component {
  render() {
    let { color } = this.props 

    return (
      <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={color} fillRule="nonzero">
            <path d="M8,16 C3.58866667,16 0,12.4113333 0,8 C0,3.58866667 3.58866667,0 8,0 C12.4113333,0 16,3.58866667 16,8 C16,12.4113333 12.4113333,16 8,16 Z M8,1.33333333 C4.324,1.33333333 1.33333333,4.324 1.33333333,8 C1.33333333,11.676 4.324,14.6666667 8,14.6666667 C11.676,14.6666667 14.6666667,11.676 14.6666667,8 C14.6666667,4.324 11.676,1.33333333 8,1.33333333 Z" id="Shape"></path>
            <circle cx="5.66666667" cy="5.85333333" r="1"></circle>
            <circle cx="10.3333333" cy="5.85333333" r="1"></circle>
            <path d="M8.12731614,12.6666667 C6.47064947,12.6666667 4.92331614,11.7726667 4.08998281,10.334 C3.90531614,10.0153333 4.01398281,9.60733333 4.33264947,9.42333333 C4.65198281,9.23866667 5.05931614,9.348 5.24398281,9.666 C5.83931614,10.6946667 6.94464947,11.3333333 8.12731614,11.3333333 C9.31064947,11.3333333 10.4159828,10.6946667 11.0106495,9.666 C11.1946495,9.34733333 11.6006495,9.23666667 11.9213161,9.42333333 C12.2399828,9.60733333 12.3493161,10.0153333 12.1639828,10.334 C11.3319828,11.7726667 9.78464947,12.6666667 8.12731614,12.6666667 Z" id="Path"></path>
          </g>
        </g>
      </svg>
    )
  }
}

export { Twitter, Facebook, LinkedIn, Smile } 
