import React from 'react'
import { StaticQuery, Link, graphql } from 'gatsby'
import { Smile  } from './icons'

const Footer = (props) => (
  <StaticQuery
    query={graphql`
      query {
        wp {
          siteSettings {
            acf {
              address
              email
              phone
              footerText
            }
          }
        }
      }
    `}
    render={(data) => (
      <footer className='footer'>
        <div className='footer__inner innercontainer'>
          <div className='footer__top'>
            <div className="info">
              <h2>Brooke Bird</h2>
            </div>
            <div className="outerlist">
              <p>{data.wp.siteSettings.acf.footerText}</p>
              <div className="blah">
                <ul className="innerlist">
                  <li>Address</li>
                  { data.wp.siteSettings.acf.address.split('\n').map((el, i) => (<li key={i}>{ el }</li>)) }
                </ul>
              </div>
              <div className="blah">
                <ul className="innerlist">
                  <li>Contact</li>
                  <li><a href={`tel:${data.wp.siteSettings.acf.phone}`}>{ data.wp.siteSettings.acf.phone }</a></li>
                  <li><a href={`mailto:${data.wp.siteSettings.acf.email}`}>{ data.wp.siteSettings.acf.email }</a></li>
                </ul>
              </div>
              <div className="blah blah--long">
                <ul className="innerlist">
                  <li><Link to='/services'>Services</Link></li>
                  <li><Link to='/expertise/liquidations/'>Liquidations</Link></li>
                  <li><Link to='/expertise/business-rescue/'>Business Rescue</Link></li>
                  <li><Link to='/expertise/bankruptcy/'>Bankruptcy</Link></li>
                </ul>
              </div>
              <div className="blah blah--short">
                <ul className="innerlist">
                  <li>About</li>
                  <li><Link to="/meet-our-partners">Our Team</Link></li>
                  <li><Link to="/blog">Blog</Link></li>
                  <li><Link to="/contact">Contact Us</Link></li>
                </ul>
              </div>
            </div>
          </div>
          <div className='footer__bottom'>
            <div className="footer__bottomleft">
              <ul>
                <li>Copyright © 2021 Brooke Bird. All Rights Reserved.</li>
                <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
                <li><Link to='/privacy-policy'>Terms and Conditions</Link></li>
              </ul>
              <ul>
                <li>Website by <a href='https://jessknollmeyer.com' target='_blank' rel='noopener noreferrer'><Smile color={`rgba(255, 255, 255, 0.8)`} /></a> and <a href='https://codedrips.com' target='_blank' rel='noopener noreferrer'>Codedrips</a></li>
              </ul>
            </div>
            <div className="footer__liability">
              <ul>
                <li>Liability limited by a scheme approved under Professional Standards Legislation</li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    )}
  />
)

export default Footer
