import React, { Component } from "react"
import { Link } from 'gatsby'

import Logo from '../images/logo.svg'
import LogoText from '../images/logo-text.svg'


let html2canvas = null

function getCorrectTextColor(color){
  let threshold = 230; /* about half of 256. Lower threshold equals more dark text on dark background  */

  let cBrightness = ((color[0] * 299) + (color[1] * 587) + (color[2] * 114)) / 1000;
  if (cBrightness > threshold) {
    return true;
  } else {
    return false;
  }
}

class Header extends Component {

  isScrolling = false

  state = {
    show: true,
    menuActive: false,
    headerColor: false,
  }

  componentDidMount() {
    html2canvas = require('html2canvas')
    this.setColor()
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      //Perform some operation here
      this.setState({ headerColor: false })
      //this.setColor()
    }
  }

  setColor = () => {
    let options = {
      width: 1,
      height: 1,
      x: window.scrollX + 5,
      y: window.scrollY + 100,
      useCORS: true,
      ignoreElements: (element) => element.className.includes && element.className.includes('gm-style'),
    }

    html2canvas(document.querySelector('body'), options).then(canvas => {
      let pixelData = canvas.getContext('2d').getImageData(0, 0, 1, 1).data
      let headerColor = getCorrectTextColor(pixelData)
      this.setState({ headerColor })
    });
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
    let { scrolled } = this.state
    let nextState = currentScroll > 0
    if (nextState !== scrolled) this.setState({ scrolled: nextState })
    this.isScrolling = setTimeout(this.setColor, 66);
  }

  toggleMenu = () => {
    const { show } = this.state
    this.setState({ show : !show })
  }

  toggleOverlay = () => {
    this.setState({ menuActive: !this.state.menuActive })
    document.querySelector('body').classList.toggle('menu-open')
  }

  _toggleOffCanvas = () => {
    this.setState({ menuActive: !this.state.menuActive })
    document.querySelector('body').classList.toggle('menu-open')
  }

  _hideOffCanvas = () => {
    this.setState({ menuActive: false })
    document.querySelector('body').classList.toggle('menu-open')
  }

  render() {
    const { menuActive } = this.state
    let { headerColor, scrolled } = this.state
    const { menu } = this.props

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }
    let headerClass = `header`
    if (scrolled) headerClass += ' header--scrolled'
    if (headerColor) headerClass += ' invert'
    if (menuActive) headerClass += ' header--menu'

    return (
      <>
        <header className={headerClass}>
          <div className="header__inner">
            <div className="header__left">
              <Link to="/">
                <img src={Logo} alt="Brooke Bird" />
                <img className='logo-text' src={LogoText} alt="Brooke Bird" />
              </Link>
            </div>
            <div className="header__right">
              <button className={ this.state.menuActive ? 'hamburger active' : 'hamburger' } onClick={this._toggleOffCanvas}>
              <p>Menu</p>
              <span className="lines"></span>
              </button>
            </div>
          </div>
        </header>

        <div className={`${menuActive ? 'off-canvas active' : 'off-canvas'}`}>
          <nav className="off-canvas__nav">
            <ul>
              { menu.siteSettings.acf.mainMenu && menu.siteSettings.acf.mainMenu.map((el, i) => {
                return (
                  <li key={i}>
                    <Link to={el.link} {...props}>
                      <p>{el.subtitle}</p>
                      <h4>{el.title}</h4>
                    </Link>
                  </li>
                )
              }) }
            </ul>
          </nav>
        </div>
      </>
    )
  }
}

export default Header
